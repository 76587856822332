<template>
  <div>
    <!-- แถบแรก -->
    <b-card no-body>
      <div class="card-content">
        <b-card-body>
          <h4 class="card-title">
            ข้อมูลประกาศ
          </h4>
          <hr />
          <div class="mt-3" />
          <form id="form_upload" method="POST">
            <b-row>
              <b-col cols="md-4">
                <b-form-group>
                  <label class="control-label">ชื่อประกาศ</label>
                  <input type="text" v-model="result.name" name="name" ref="name" class="form-control"  placeholder="ข้อมูลชื่อประกาศ">
                </b-form-group>
              </b-col>
              <b-col cols="md-4">
                <b-form-group>
                  <label class="control-label">เรียงลำดับ</label>
                  <input type="number" v-model="result.seq" name="seq" ref="seq" class="form-control"  placeholder="เรียงลำดับ">
                </b-form-group>
              </b-col>
              <b-col cols="md-4">
                <b-form-group>
                  <label class="control-label">สถานะ</label>
                  <b-form-select name="status" ref="status" v-model="result.status" :options="option" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="md-12 mb-1">
                <label>รายละเอียด</label>
                <quill-editor
                  v-model="result.content"
                  :options="snowOption"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="md-12">
                <b-form-group>
                  <label>รูปภาพ (1040x1040)</label>
                    <input type="file" name="image" ref="image" class="dropify" data-height="300" accept=".jpg,.jpeg,.png" data-allowed-file-extensions='["jpg", "jpeg", "png"]'  />
                  <input type="text" v-model="result.image_url" name="image_url" ref="image_url" hidden>
                </b-form-group>
              </b-col>
            </b-row>
          </form>
          <hr />
          <b-row>
            <b-col cols="md-12">
              <div class="text-right m-b-10">
                <div class="text-right m-b-10">
                  <router-link :to="PATH" class="btn bg-gradient-warning waves-effect waves-light mr-1"><span><feather-icon icon="ArrowLeftIcon" class="font-medium-1 mr-1" /></span>ย้อนกลับ</router-link>
                  <button @click="update()" type="submit" class="btn bg-gradient-success waves-effect waves-light text-white"><span><feather-icon icon="SaveIcon" class="font-medium-1 mr-1" /></span>บันทึก</button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </div>
    </b-card>
    <!-- สิ้นสุดแถบแรก -->
    <!-- สิ้นสุดท้าย -->
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCol, BRow, BTable, BFormGroup, BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import 'dropify/dist/js/dropify.min.js'
import 'dropify/dist/css/dropify.min.css'
import axios from 'axios'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
    BRow,
    BTable,
    BFormGroup,
    BFormSelect,
    vSelect,
    quillEditor,
  },
  data() {
    return {
      PATH: '/news',
      BASE_URL: process.env.BASE_URL,
      API_UPDATE_URL: 'news/new_create',
      snowOption: {
        theme: 'snow',
      },
      result: {
        content: '',
        name: '',
        seq: 0,
        status: 1,
        image: '',
        image_url: '',
      },
      option: [
        { text: 'ปิด', value: '0' },
        { text: 'เปิด', value: '1' },
      ],
      bank_list: JSON.parse('{"01":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","02":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","03":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","04":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","05":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","06":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","07":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","08":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","09":"\u0e18.\u0e01.\u0e2a.","1":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","2":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","3":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","4":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","5":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","6":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","7":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","8":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","9":"\u0e18.\u0e01.\u0e2a."}'),
    }
  },
  mounted() {
    this.fetch()
    this.dropify()
  },
  methods: {
    alertText(text='สำเร็จ!', type='success') {
      this.$swal({
        title: 'แจ้งเตือน',
        text: text,
        icon: type,
        confirmButtonText: 'เรียบร้อย',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    dropify() {
      var self = this
      this.drEvent = $(this.$el).find('.dropify').dropify({
        tpl: {
          wrap: '<div class="dropify-wrapper" style="border-radius: 5px;"></div>',
          loader: '<div class="dropify-loader"></div>',
          message: '<div class="dropify-message"><span class="file-icon"></span><br /> {{ default }}</div>',
          preview: '<div class="dropify-preview"><span class="dropify-render"></span><div class="dropify-infos"><div class="dropify-infos-inner"><p class="dropify-infos-message">{{ replace }}</p></div></div></div>',
          filename: '<p class="dropify-filename"><span class="file-icon"></span> <span class="dropify-filename-inner"></span></p>',
          clearButton: '<button type="button" class="dropify-clear">{{ remove }}</button>',
          errorLine: '<p class="dropify-error">ตรวจสอบชนิดไฟล์ให้ถูกต้อง</p>',
          errorsContainer: '<div class="dropify-errors-container"><ul></ul></div>'
        }
      });
      this.drEvent.on('dropify.fileReady', function(event, element) {
        if (element) {
          const data = new FormData(document.getElementById('form_upload'))
          axios.post('https://api.imgur.com/3/image', data, {
            headers: {
              Authorization: 'Client-ID 43905ab7492a794',
              Accept: 'application/json'
            }
          }).then(response=>{
            let res = response.data
            if (res.success) {
              self.result.image_url = res.data.link
              self.alertText('อัพโหลดรูปสำเร็จ', 'success')
            } else {
              self.alertText('อัพโหลดรูปภาพไม่สำเร็จ', 'error')
            }
          }).catch(error=>{
              self.alertText('ทำรายการไม่สำเร็จ '+error, 'warning')
          })
        }
      });
    },
    toMoney(value) {
      const parts = parseFloat(value).toFixed(2).split('.')
      return parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + (parts[1] ? `.${parts[1]}` : '')
    },
    loading() {
      this.$swal({
			  text: "กรุณารอสักครู่..",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: '',
        onBeforeOpen: () => {
          this.$swal.showLoading()
        },
      });
    },
    success() {
      this.$swal({
        title: 'แจ้งเตือน',
        text: 'แก้ไขสำเร็จแล้ว!',
        icon: 'success',
        confirmButtonText: 'เรียบร้อย',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    warn(text='') {
      this.$swal({
        title: 'แจ้งเตือน',
        text: text,
        icon: 'warning',
        confirmButtonText: 'ตกลง',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    fetch() {
    },
    update() {
      if (this.result.name.trim().length == '') {
        this.$refs.name.focus()
        return this.warn('กรุณาระบุชื่อประกาศ')
      }
      if (this.result.seq.length ==  0) {
        this.$refs.seq.focus()
        return this.warn('กรุณาระบุลำดับ')
      }
      this.result.status = Number(this.result.status)
      if (this.result.image_url.trim().length == '') {
        this.$refs.image_url.focus()
        return this.warn('กรุณาระบุภาพ')
      }
      this.loading()
      this.$http.post(this.API_UPDATE_URL, this.result).then(res=> {
        this.$swal.close()
        this.$router.push(this.PATH)
      }).catch(()=>this.$router.push(this.PATH))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
body.dark-layout .dropify-wrapper:hover { background-image: -webkit-linear-gradient(-45deg,#283046 25%,transparent 25%,transparent 50%,#283046 50%,#283046 75%,transparent 75%,transparent); background-image: linear-gradient(-45deg,#283046 25%,transparent 25%,transparent 50%,#283046 50%,#283046 75%,transparent 75%,transparent);}
body.dark-layout .dropify-wrapper { background-color: #161d30; }
</style>
